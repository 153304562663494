// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bw_k3";
export var caseStudyBackground__lineColor = "bw_k0";
export var caseStudyHead = "bw_kW";
export var caseStudyHead__imageWrapper = "bw_kX";
export var caseStudyProjectsSection = "bw_k4";
export var caseStudyQuote__bgLight = "bw_kY";
export var caseStudyQuote__bgRing = "bw_kZ";
export var caseStudyVideoReview = "bw_l1";
export var caseStudyVideoReview__bgRing = "bw_l2";
export var caseStudyVideo__ring = "bw_k2";
export var caseStudy__bgDark = "bw_kV";
export var caseStudy__bgLight = "bw_kT";
export var caseStudy__bgLightReverse = "bw_l0";